.smallScreenNav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

.smallScreenNav a{
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;

}

.smallScreenNav a:hover {
    background: rgba(0, 0, 0, 0.3);
}

.smallScreenNav a.active {
    background: var(--color-primary);
    color: var(--color-bg);
}


@media screen and (max-width:600px){
    .smallScreenNav{
        display: inline-flex;
    }
}

.smallScreen{
    display: none;
}