.contact-left{
    flex-basis: 35%;
}

.contact-right{
    flex-basis: 60%;
}

.contact-left p{
    margin-top: 30px;
}

.social-icons a{
    margin-top: 30px;
    text-decoration: none;
    font-size: 30px;
    margin-right: 15px;
    color: #ababab;
    display: inline-block;
    transition: var(--transition);
}

.social-icons a:hover{
    color: #ec6324;
    transform: translateY(-5px);
}

.btn{
    display: block;
    margin: 50px auto;
    width: fit-content;
    border: 1px solid #ec6324;
    padding: 14px 50px;
    border-radius: 6px;
    text-decoration: none;
    color: white;
    transition: var(--transition);
}
.btn:hover{
    background: #ec6324;
}
.btn.btn2{
    display: inline-block;
    background: #ec6324;
}

.contact-rightn form{
    width: 100%;
}

form input, form textarea{
    width: 100%;
    border: 0;
    outline: none;
    background: #262626;
    padding: 15px;
    margin: 15px 0;
    color: #fff;
    font-size: 18px;
    border-radius: 6px;
}

form .btn2{
    padding: 14px 60px;
    font-size: 18px;
    margin-top: 20px;
    cursor: pointer;
}
.copyright{
    width: 100%;
    text-align: center;
    padding: 25px 0;
    font-weight: 300;
    margin-top: 20px;
}

/* ===========SMALL SCREEN ============ */

@media screen and (max-width:600px){
    .contact-left, .contact-right{
        flex-basis: 100%;
    }
    .copyright{
        font-size: 14px;
    }
}