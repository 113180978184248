#about{
    padding: 80px 0;
    color: #ababab;
}

.row{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.about-col-1{
    flex-basis: 35%;
}

.about-col-1 img{
    width: 100%;
    border-radius: 15px;
}

.about-col-2{
    flex-basis: 60%;

}

.sub-title{
    font-size: 60px;
    color: white;
    font-weight: 600;
}

.tab-title{
    display: flex;
    margin: 20px 0 40px;
}

.tab-links{
    margin-right: 50px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
}

.tab-links::after{
    content: '';
    width: 0;
    height: 3px;
    background: #ec6324;
    position: absolute;
    left: 0;
    bottom: -8px;
    transition: var(--transition);
}

.tab-links:hover::after{
    width: 100%;

}

.tab-links.active-link::after{
    width: 50%;
}

.tab-contents ul li{
    list-style-type: none;
    margin: 10px 0;

}

.tab-contents ul li span{
    color: #ec6324;
    font-size: 14px;
}

.tab-contents{
    display: none;
}

.tab-contents.active-tab{
    display: block;
}


/* ===========SMALL SCREEN ============ */

@media screen and (max-width:600px){
    .sub-title{
        font-size: 40px;
    }
    .about-col-1, .about-col-2{
        flex-basis: 100%;
    }
    .about-col-1{
        margin-bottom: 30px;
    }
    .about-col-2{
        font-size: 14px;
    }
    .tab-links{
        font-size: 16px;
        margin-right: 20px;
    }
}