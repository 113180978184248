*{
    margin: 0;
    padding: 0;
    font-family: 'poppins', sans-serif;
    box-sizing: border-box;
}

body{
    background: #080808;
    color: #fff;
}

:root{
    --transition: all 400ms ease;
}

html{
    scroll-behavior: smooth;
}




